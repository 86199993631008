import { AwsRum, AwsRumConfig } from 'aws-rum-web';

// Types
interface RumConfiguration {
  applicationId: string;
  applicationVersion: string;
  applicationRegion: string;
  guestRoleArn: string;
  identityPoolId: string;
  endpoint: string;
  telemetries: string[];
}

// Constants
const DEFAULT_CONFIG: Partial<AwsRumConfig> = {
  sessionSampleRate: 1,
  allowCookies: true,
  enableXRay: true,
  enableRumClient: true,
  disableAutoPageView: false,
  pageIdFormat: 'PATH_AND_HASH'
};

const DEFAULT_RUM_CONFIG: RumConfiguration = {
  applicationId: '857c157b-6579-4663-ab00-c09fa414f907',
  applicationVersion: '1.0.0',
  applicationRegion: 'us-west-2',
  guestRoleArn: 'arn:aws:iam::287997430343:role/DaS-FinSuite-Portal-Cogni-CognitoUnauthenticatedRo-WOW47I1R3S7Q',
  identityPoolId: 'us-west-2:814f52b2-122c-445b-9889-e8553fa9184c',
  endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
  telemetries: ['performance', 'errors', 'http']
};

export class RumClient {
  private static instance: AwsRum;

  private constructor() {}

  static initialize(userAlias: string): AwsRum | null {
    if (this.instance) {
      return this.instance;
    }

    try {
      const rumConfig = this.getConfiguration();
      const config: AwsRumConfig = {
        ...DEFAULT_CONFIG,
        guestRoleArn: rumConfig.guestRoleArn,
        identityPoolId: rumConfig.identityPoolId,
        endpoint: rumConfig.endpoint,
        telemetries: rumConfig.telemetries
      };

      this.instance = new AwsRum(rumConfig.applicationId, rumConfig.applicationVersion, rumConfig.applicationRegion, config);

      this.instance.addSessionAttributes({ userAlias });
      return this.instance;
    } catch (error) {
      console.warn('Failed to initialize RUM client:', error);
      return null;
    }
  }

  static getInstance(): AwsRum | null {
    return this.instance || null;
  }

  private static getConfiguration(): RumConfiguration {
    // This could be extended to fetch configuration from environment variables or other sources
    return DEFAULT_RUM_CONFIG;
  }

  static recordEvent<T extends Record<string, unknown>>(eventName: string, eventData: T): void {
    if (!this.instance) {
      console.warn('RUM client not initialized');
      return;
    }

    try {
      this.instance.recordEvent(eventName, eventData);
    } catch (error) {
      console.warn(`Failed to record event ${eventName}:`, error);
    }
  }

  static recordApiRequest(data: any): void {
    this.recordEvent('apiRequest', data);
  }
}

export function recordApiRequest(queryName: string, responseTime: number): void {
  RumClient.recordApiRequest({ queryName, responseTime });
}

/*

// Example usage:


export function initializeRumClient(userAlias: string): AwsRum | null {
  return RumClient.initialize(userAlias);
}

export function recordCustomEvent<T extends Record<string, unknown>>(eventName: string, eventData: T): void {
  RumClient.recordEvent(eventName, eventData);
}

// Initialize
const rum = initializeRumClient('user123');

// Record custom event
recordCustomEvent('my_custom_event', {
  location: 'IAD',
  current_url: 'amazonaws.com',
  user_interaction: {
    interaction_1: "click",
    interaction_2: "scroll"
  },
  visit_count: 10
});

// Record API request
recordApiRequest('getUserData', 250);
*/
